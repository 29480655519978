import { Search, Icon, Swipe, SwipeItem, Tag, List } from 'vant'
import Login from '../../intercept/Login.vue'
import Share from '../../common/Share.vue'
import Loading from '../../common/Loading.vue'
import LogVisit from '../../common/LogVisit.vue'
import Clue from '../../common/Clue.vue'
import MajorSift from '../common/MajorSift.vue'
import Call from '../../common/Call.vue'
import '../../../../assets/icon/font-icon.js'
export default {
    components: {
        Login: Login,
        Share: Share,
        Loading: Loading,
        LogVisit: LogVisit,
        Clue: Clue,
        MajorSift: MajorSift,
        Call: Call,
        [Search.name]: Search,
        [Icon.name]: Icon,
        [Swipe.name]: Swipe,
        [SwipeItem.name]: SwipeItem,
        [Tag.name]: Tag,
        [List.name]: List
    },
    data () {
        return {
            loadingShow: false,
            finished: false,
            keyword: '',
            classifyCode: '',
            page: { current: 0, size: 20, last: 0 },
            majorList: []
        }
    },
    mounted () {
        this.retrieveMajorList()
        this.$refs.share.default('main')
        this.createVistClue()
    },
    methods: {
        search () {
            this.page.current = 0
            this.finished = false
            this.majorList = []
            this.retrieveMajorList()
        },
        siftSearch (condition) {
            this.classifyCode = condition.classifyCode
            this.search()
        },
        async importMajor () {
            const { data: res } = await this.$http.post(this.BMS_URL + '/academic/major/importMajor')
            if (res.status === '200') {
                console.log(res.data)
            }
        },
        async retrieveMajorList () {
            if (!this.finished) {
                this.loadingShow = true
                this.page.current = this.page.current + 1
                var pd = { keyword: this.keyword, classifyCode: this.classifyCode, current: this.page.current, size: this.page.size }
                var { data: res } = await this.$http.post(this.BMS_URL + '/academic/major/retrieveAPCMajorPage', this.$qs.stringify(pd))
                if (res.status === '200') {
                    console.log(res.data)
                    this.majorList = this.majorList.concat(res.data)
                    this.page.last = res.page.last
                    if (res.data.length <= 0) {
                        this.finished = true
                    }
                }
                this.loadingShow = false
            }
        },
        detail (code) {
            var key = window.const.global.ACADEMIC_DETAIL_INDEX_BACK
            window.sessionStorage.setItem(key, window.location.href)
            this.$router.push({ path: '/mde/academic/detail', query: { code: code } })
        },
        createVistClue () {
            var param = { url: window.location.href }
            var content = '职称考评首页访问'
            this.$refs.clue.createClue('ACADEMIC_VIST_HOME', '0', JSON.stringify(param), content)
        },
        back () {
            var back = window.sessionStorage.getItem(window.const.global.ACADEMIC_HOME_INDEX_BACK)
            if (back !== undefined && back !== null && back !== '') {
                window.location.href = back
            } else {
                this.$dialog.alert({
                    title: '提示信息',
                    message: '已返回到最上层'
                })
            }
        }
    }
}
