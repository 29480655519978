import { DropdownMenu, DropdownItem, TreeSelect } from 'vant'
export default {
    components: {
        [DropdownMenu.name]: DropdownMenu,
        [DropdownItem.name]: DropdownItem,
        [TreeSelect.name]: TreeSelect
    },
    data () {
        return {
            activeId: '',
            activeIndex: 1,
            condition: {},
            classifyChose: {},
            classify: { text: '按分类选择', id: '' },
            classifys: [{ id: '', text: '全部分类', children: [{ id: '', text: '全部分类' }] }]
        }
    },
    props: {
        callback: Function
    },
    mounted () {
        this.retrieveClassifyTree()
    },
    methods: {
        confirmClassify () {
            this.condition.classifyCode = this.classify.id
            this.callback(this.condition)
        },
        async retrieveClassifyTree () {
            var pd = { upperCode: '0' }
            const { data: res } = await this.$http.post(this.BMS_URL + '/academic/classify/retrieveClassifyTree', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.classifys = this.classifys.concat(res.data)
            }
        },
        clickClassifyNav (index) {
            this.activeIndex = index
        },
        clickClassifyItem (item) {
            this.classify = item
            this.$refs.classify.toggle()
            this.confirmClassify()
        }
    }
}
