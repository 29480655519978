<template>
  <div class="page">
    <Login ref="login" :callback="init" mode="GST" />
    <div class="search">
      <van-row>
        <van-col span="3" @click="back()"
          ><div class="back-btn">
            <van-icon name="arrow-left" />返回
          </div></van-col
        >
        <van-col span="21">
          <van-search
            v-model="keyword"
            placeholder="请输入搜索关键词"
            ref="keyword"
            show-action
            ><template #action>
              <div @click="search">搜索</div>
            </template></van-search
          >
        </van-col>
      </van-row>
    </div>
    <van-swipe class="banner" :autoplay="6000" indicator-color="white">
      <van-swipe-item>
        <img
          class="img"
          :src="require('../../../../assets/images/academic/desc.jpg')"
        />
      </van-swipe-item>
    </van-swipe>
    <div class="list">
      <van-row class="title">
        <van-col span="12" class="text"
          ><van-icon name="notes-o" /> 职称专业列表</van-col
        >
      </van-row>
      <MajorSift :callback="siftSearch" ref="sift" />
      <van-list
        :finished="finished"
        finished-text="没有更多了"
        offset="0"
        :immediate-check="false"
        @load="retrieveMajorList"
      >
        <template #default>
          <div
            class="major"
            v-for="major in majorList"
            :key="major.code"
            @click="detail(major.code)"
          >
            <div class="name text-short">
              {{ major.name }}【{{ major.classifyName }}】
            </div>
            <div class="grade">
              <van-tag
                :color="COLOR_M"
                class="item"
                v-for="grade in major.grades"
                :key="grade"
                >{{ grade }}</van-tag
              >
            </div>
          </div>
        </template>
      </van-list>
    </div>
    <Call
      ref="call"
      win-show="false"
      entry-show="true"
      clue-model="ACADEMIC_CONSULT_ONLINE"
    />
    <Loading :show="loadingShow" />
    <Share ref="share" />
    <LogVisit ref="logVisit" module-type="ADC" module-label="ADC_HOME_INDEX" />
    <Clue ref="clue" />
  </div>
</template>
<script>
import Index from './Index.js'
export default {
  ...Index
}
</script>
<style lang="less" scoped>
@import './Index.less';
</style>
