<template>
  <div>
    <van-dropdown-menu class="dropdown">
      <van-dropdown-item
        v-model="classify"
        :title="classify.text"
        ref="classify"
        ><van-tree-select
          :active-id="activeId"
          :main-active-index="activeIndex"
          :items="classifys"
          @click-nav="clickClassifyNav"
          @click-item="clickClassifyItem"
      /></van-dropdown-item>
    </van-dropdown-menu>
  </div>
</template>
<script>
import MajorSift from './MajorSift.js'
export default {
  ...MajorSift
}
</script>
<style lang="less" scoped>
@import './MajorSift.less';
</style>
